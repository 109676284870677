import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookSpells, faBat } from '@fortawesome/pro-solid-svg-icons';
import './Questions.css';

class Questions extends Component {
  render() {
    return (
      <BodyClassName className="faq-page">
        <div className="content-wrapper">
          <h3><FontAwesomeIcon icon={faBookSpells} /> Frequently Asked Questions</h3>
          <ul className="questions">
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">Who can send pitches?</div>
              <div className="a">We are an inclusive publisher, that encourages everyone to to submit pitches to Gothology. However, you must be 18 years before the pitch period is over to be considered.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">But I'm not a goth! Can I still submit?</div>
              <div className="a">Yes. Gothology is a very tongue in cheek series, and is not meant to be taken seriously.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">What is the theme exactly?</div>
              <div className="a">The theme is anything related to the Goth subculture. From Goth kids at the mall to horror, and everything inbetween. We like it dark, we like it weird.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">What should I include in my pitch?</div>
              <div className="a">Required items include name, email, a link with art samples, an outline of your story, and page count. If you have anything else to show, feel free to send it our way, as well.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">When do I have to submit my pitch by?</div>
              <div className="a">Due to Gothology being postponed, there is no current due date.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">Past Gothology entries weren't paid, will this one be?</div>
              <div className="a">Yes, the chosen contributors will be paid at least $50/page.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">How many pages can I pitch?</div>
              <div className="a">We encourage comics between 1-5 pages, but the max amount of pages that will be considered is 15.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">Can I pitch a mature comic?</div>
              <div className="a">For the most part, yes. We are very lenient with what we will accept. However, we will not publish porn or anything hateful.</div>
            </li>
            <li className="question-item">
              <FontAwesomeIcon icon={faBat} size="2x" />
              <div className="q">I'm an artist/writer looking for a team. Can you help?</div>
              <div className="a">Sorry, we can not help with that. Instead, we highly encourage you to find people on your own that you are comfortable working with.</div>
            </li>
          </ul>
          <p className="left">Don't see your question? @ us on Twitter (<a href="https://twitter.com/gothology" target="_blank" rel="noopener noreferrer">@gothology</a>) or send an email to <a href="mailto:gothology@dapshow.com">gothology@dapshow.com</a>, and we'll be miserable to respond.</p>
        </div>
      </BodyClassName>
    );
  }
}

export default Questions;
