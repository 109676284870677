import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookDead } from '@fortawesome/pro-solid-svg-icons';
import './Books.css';

class Books extends Component {
  render() {
    return (
      <BodyClassName className="book-page">
        <div className="content-wrapper">
          <h3 className="book-title"><FontAwesomeIcon icon={faBookDead} /> Past Books</h3>
          <div className="books">
            <figure className="book">
              <a href="https://shop.dapshow.com/products/gothology-the-eternal-sad" target="_blank" rel="noopener noreferrer">
                <span className="booknum">Book I</span>
                <img src="https://cdn.shopify.com/s/files/1/1528/3207/products/goth1_grande.jpg?v=1476930777" alt="the eternal sad" />
                <span>The Eternal Sad</span>
              </a>
            </figure>
            <figure className="book">
              <a href="https://shop.dapshow.com/products/gothology-misery-loves-company-2010-comic-anthology" target="_blank" rel="noopener noreferrer">
                <span className="booknum">Book II</span>
                <img src="https://cdn.shopify.com/s/files/1/1528/3207/products/goth2_grande.jpg?v=1498453702" alt="misery loves company" />
                <span>Misery Loves Company</span>
              </a>
            </figure>
          </div>
        </div>
      </BodyClassName>
    );
  }
}

export default Books;
