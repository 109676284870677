import React, { Component } from 'react';
import { Link } from  'react-router-dom';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkull } from '@fortawesome/free-solid-svg-icons';
import './LinkItem.css';

const AItem = styled(Link)`
  &:before, &:after {
    content: url(data:image/svg+xml,${encodeURIComponent(renderToString(<FontAwesomeIcon icon={faSkull} style={{color:"#ffffff"}} />))});
  }
`;

class LinkItem extends Component {
  render() {
    return (
      <AItem to={this.props.link} className="link-item">
        {this.props.children}
      </AItem>
    );
  }
}

export default LinkItem;
