import React, { Component } from 'react';
import { BrowserRouter, Route, NavLink } from 'react-router-dom';
import firebase from "firebase/app";
import Preload from 'react-preload';
import { GridLoader } from 'react-spinners';
import Landing from './Landing';
import Pitches from './pitches/Pitches';
import Questions from './Questions';
import Books from './Books';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGhost } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faPencilPaintbrush, faBookDead, faCrow, faBookSpells } from '@fortawesome/pro-solid-svg-icons';
import candleImg from './candleskull.png';
import './App.css';

// Setup Firebase
firebase.initializeApp({
  apiKey: "AIzaSyCpE5EFDg8ov0nXwpQB-goJsPjN03DM7-0",
  databaseURL: 'https://gothology-submissions.firebaseio.com/',
  projectId: 'gothology-submissions',
  storageBucket: "gothology-submissions.appspot.com"
});

library.add(faGhost, fab);
const amazonURL = 'https://s3.amazonaws.com/dapshow/gothology/bg';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      error: false,
    };
  }

  _handleImageLoadError = (failedImages) => {
    this.setState({
      error: true
    });
  }

  _handleImageLoadSuccess = () => {
    this.setState({
      loaded: true
    });
  }

  componentDidMount() {
    this.bustCache();
    import('webfontloader').then(WebFontLoader => {
      WebFontLoader.load({
        typekit: {
          id: 'xsk5grv',
        }
      });
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  _handleClickNewImages = () => {
    this.bustCache()
  }

  bustCache = () => {
    this.setState({
      cacheBreaker: Date.now(),
    });
  }

  appClasses = () => {
    if (this.state.loaded) {
      return 'active';
    }
    return 'app-loading';
  }

  renderNavMenu = () => {
    return (
      <nav className="menu-nav">
        <NavLink exact to="/" className="menu-item" activeClassName="selected"><FontAwesomeIcon icon={faCrow} color="white" /> Home</NavLink>
        <NavLink exact to="/books" className="menu-item" activeClassName="selected"><FontAwesomeIcon icon={faBookDead} color="white" /> Books</NavLink>
        <NavLink exact to="/pitches" className="menu-item" activeClassName="selected"><FontAwesomeIcon icon={faPencilPaintbrush} color="white" /> Pitch</NavLink>
        <NavLink exact to="/questions" className="menu-item" activeClassName="selected"><FontAwesomeIcon icon={faBookSpells} color="white" /> FAQ</NavLink>
        <div className="menu-line"></div>
      </nav>
    );
  }

  render() {
    const imageList = [
      `${amazonURL}/pink-smoke.png`,
      `${amazonURL}/birds.png`,
      `${amazonURL}/crow-cemetery.png`,
      `${amazonURL}/pink-smoke-2.jpg`,
    ];

    return (
      <BrowserRouter>
        <div className={`app overlay ${this.appClasses()}`}>
          <Preload
            images={imageList}
            onError={this._handleImageLoadError}
            onSuccess={this._handleImageLoadSuccess}
            loadingIndicator={
              <GridLoader
                sizeUnit={'px'}
                size={24}
                color={'#b50742'}
                loading={this.state.loading}
              />
            }
            mountChildren
            resolveOnError
          >
            <div className="app-content">
              <header className="app-header">
                <img src={candleImg} alt="candle skull" />
                <h1><NavLink to="/">Gothology.</NavLink></h1>
                {this.renderNavMenu()}
              </header>
              <main className="app-body">
                <Route exact path="/pitches" component={Pitches} />
                <Route exact path="/questions" component={Questions} />
                <Route exact path="/books" component={Books} />
                <Route exact path="/" component={Landing} />
              </main>
              <footer className="app-footer">
                <div className="footer-links">
                  <a href="https://twitter.com/gothology" target="_blank" rel="noopener noreferrer" title="Gothology Comics on Twitter" className="link"><FontAwesomeIcon size="2x" icon={['fab', 'twitter']} /></a>
                  <a href="https://instagram.com/gothologycomics" target="_blank" rel="noopener noreferrer" title="Gothology Comics on Instagram" className="link"><FontAwesomeIcon size="2x" icon={['fab', 'instagram']} /></a>
                </div>
                <span className="footer-detail">
                  Gothology Comics is brought to you by <a href="http://dap.show">DAPshow</a>.
                </span>
                <span className="footer-detail">
                  bathaus <strong>will</strong> prevail.
                </span>
              </footer>
            </div>
          </Preload>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
