import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import PitchSubmission from './PitchSubmission';
import './Pitches.css';

class Pitches extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submissionComplete: false,
    };
  }

  renderNotes = () => {
    if (!this.state.submissionComplete) {
      return (
        <div>
          <h3>Send us your pitches</h3>
          <p>What to include in your pitch:</p>
          <ul>
            <li>Name, email, and a portfolio link (or a social media account with art examples). These are required.</li>
            <li>Story outline and page count. This can be brief or a detailed traditional outline. This is also required. It can be attached as a file, or added to the notes section.</li>
            <li>(Optional) A bit of information about yourself.</li>
            <li>(Optional) Story art. Concepts, thumbnails, etc.</li>
          </ul>
        </div>
      );
    }
  }

  isSubmissionComplete = () => {
    this.setState({
      submissionComplete: true
    });
  }

  render() {
    return (
      <BodyClassName className="pitch-page">
        <div className={`content-wrapper ${!this.state.submissionComplete ? 'form' : ''}`}>
          {this.renderNotes()}
          <PitchSubmission status={() => this.isSubmissionComplete()} />
        </div>
      </BodyClassName>
    );
  }
}

export default Pitches;
