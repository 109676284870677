import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import TextareaAutosize from 'react-autosize-textarea';
import { css } from 'react-emotion';
import firebase from 'firebase/app';
import FileUploader from "react-firebase-file-uploader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import validator from 'validator';
import { GridLoader } from 'react-spinners';
import 'firebase/storage';
import 'firebase/firestore';
import './PitchSubmission.css';

class PitchSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filenames: [],
      downloadURLs: [],
      uploadedFiles: 0,
      uploading: false,
      errorMessage: false,
      pitchName: '',
      pitchPortfolioLink: '',
      pitchEmail: '',
      pitchBio: null,
      formErrors: { name: '', email: '', url: '' },
      touched: { name: '', email: false, url: false },
      nameValid: false,
      emailValid: false,
      urlValid: false,
      formValid: false,
      submissionComplete: false
    };
    this.errorMessage = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  fetchFileType = (fileType) => {
    const thisFileType = fileType.substring(fileType.indexOf("/") + 1);

    if (thisFileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'doc';
    }

    return thisFileType;
  }

  fetchSubmittedFile = (file, i) => {
    if (file.type.includes('image')) {
      return (
        <div className="submitted-file" key={i}>
          {file && (
            <img src={URL.createObjectURL(file)} alt={`uploaded file ${file.name}`} />
          )}
        </div>
      );
    }

    return (
      <div className="submitted-file non-image" key={i}>
        <div className="submitted-type">{this.fetchFileType(file.type)}</div>
        <FontAwesomeIcon icon="ghost" />
      </div>
    );
  }

  handleFileOnChange = event => {
    const { target: { files } } = event;
    const fileList = Array.from(files)
    const filesToStore = [];

    fileList.forEach(file => filesToStore.push(file));

    this.setState({ filenames: this.state.filenames.concat(filesToStore) }, this.validateForm);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      this.validateField(name, value)
    });
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let urlValid = this.state.urlValid;

    switch(fieldName) {
      case 'pitchEmail':
        emailValid = validator.isEmail(value);
        fieldValidationErrors.email = emailValid ? '' : ' Email is invalid.';
        break;
      case 'pitchName':
        nameValid = value.length >= 1;
        fieldValidationErrors.name = nameValid ? '' : 'Please enter your name.';
        break;
      case 'pitchPortfolioLink':
        urlValid = validator.isURL(value);
        fieldValidationErrors.url = urlValid ? '' : 'Link is invalid.';
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      nameValid: nameValid,
      urlValid: urlValid,
    }, this.validateForm);
  }

  validateForm = () => {
    this.setState({
      formValid: this.state.emailValid && this.state.nameValid && this.state.urlValid && (this.state.filenames.length || this.state.pitchBio)
    });
  }

  hasError = error => {
    return error.length > 0;
  }

  handleBlur = (field) => (evt) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  }

  fetchErrorField = (field, blur) => {
    if (this.hasError(field) && blur) {
      return (
        <div className="pitch-input-error">{field}</div>
      );
    }
  }

  isInputFilled = content => {
    if (content !== null && content) {
      return 'pitch-input-filled'
    }

    return 'pitch-input-empty';
  }

  handlePitchSubmit = event => {
    event.preventDefault();
    this.setState({
      uploading: true
    });
    if (this.state.filenames.length > 0) {
      this.startUploadManually();
    } else {
      this.submitUsersData();
    }
  }

  startUploadManually = () => {
    const { filenames } = this.state;
    filenames.forEach(file => {
      this.fileUploader.startUpload(file);
    });
  }

  submitUsersData = (event) => {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const userRef = db.collection('pitches').add({
      name: this.state.pitchName,
      email: this.state.pitchEmail,
      url: this.state.pitchPortfolioLink,
      notes: this.state.pitchBio,
      file: this.state.downloadURLs
    });
    this.setState({
      pitchName: '',
      pitchEmail: '',
      pitchPortfolioLink: '',
      pitchBio: null,
      filenames: [],
      downloadURLs: [],
      uploading: false,
      submissionComplete: true
    });
    this.props.status();
  }

  handleUploadSuccess = event => {
    const total = this.state.filenames.length;
    this.setState(oldState => ({
      downloadURLs: [...oldState.downloadURLs, event],
      uploadedFiles: this.state.uploadedFiles + 1,
    }));
    if (this.state.uploadedFiles === total && !this.state.errorMessage) {
      this.submitUsersData();
    }
  }

  handleError = event => {
    this.setState({
      errorMessage: true
    });
    const errorDomNode = ReactDOM.findDOMNode(this.errorMessage.current)
    window.scrollTo(0, errorDomNode.offsetTop);
  }

  renderErrorMessage = () => {
    if (this.state.errorMessage) {
      return (
        <div className="error" ref={this.errotMessage}>
          There appears to have been an error with your submission. If this continues, send it to us at <a href="mailto:gothology@dapshow.com">gothology@dapshow.com</a>.
        </div>
      );
    }
  }

  checkIfLoading() {
    if (this.state.uploading) {
      const fixedLoader = css`
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
      `;
     return (
       <div className="loader-wrap">
         <GridLoader
           className={fixedLoader}
           sizeUnit={'px'}
           size={24}
           color={'#b50742'}
           loading={this.state.loading}
         />
       </div>
     );
    }
  }

  render() {
    if (this.state.submissionComplete) {
      return (
        <div className="submission-complete">
          <h3>Thank you for submitting to Gothology Comics.</h3>
          <p>If you have any further questions or comments, feel free to email us at <a href="mailto:gothology@dapshow.com">gothology@dapshow.com</a>.</p>
          <p>Contributors will be chosen after the pitch period has ended (Jan 5th, 2019), and will be notified afterwards.</p>
        </div>
      );
    }
    return (
      <form className="pitch-form" name="pitches" onSubmit={this.handlePitchSubmit}>
        {this.checkIfLoading()}
        {this.renderErrorMessage()}
        <div className={`pitch-input-wrap ${this.isInputFilled(this.state.pitchName)}`}>
          <input className="pitch-input"
            id="pitch-name"
            name="pitchName"
            type="text"
            value={this.state.pitchName}
            onBlur={this.handleBlur('name')}
            onChange={this.handleInputChange} />
          <label className="pitch-label" htmlFor="pitch-name"><span className="pitch-label-content" data-content="Name">Name</span></label>
          {this.fetchErrorField(this.state.formErrors.name, this.state.touched.name)}
        </div>

        <div className={`pitch-input-wrap ${this.isInputFilled(this.state.pitchEmail)}`}>
          <input
            className="pitch-input"
            id="pitch-email"
            name="pitchEmail"
            type="email"
            value={this.state.pitchEmail}
            onBlur={this.handleBlur('email')}
            onChange={this.handleInputChange} />
          <label className="pitch-label" htmlFor="pitch-email"><span className="pitch-label-content" data-content="Email">Email</span></label>
          {this.fetchErrorField(this.state.formErrors.email, this.state.touched.email)}
        </div>

        <div className={`pitch-input-wrap ${this.isInputFilled(this.state.pitchPortfolioLink)}`}>
          <input
            className="pitch-input"
            id="pitch-portfolio"
            name="pitchPortfolioLink"
            type="text"
            value={this.state.pitchPortfolioLink}
            onBlur={this.handleBlur('url')}
            onChange={this.handleInputChange} />
          <label className="pitch-label" htmlFor="pitch-portfolio"><span className="pitch-label-content" data-content="Portflio Link">Portfolio Link</span></label>
          {this.fetchErrorField(this.state.formErrors.url, this.state.touched.url)}
        </div>

        <div className={`pitch-input-wrap ${this.isInputFilled(this.state.pitchBio)}`}>
          <TextareaAutosize
            className="pitch-input pitch-textarea"
            id="pitch-bio"
            name="pitchBio"
            onChange={this.handleInputChange}
            defaultValue={this.state.pitchBio}
            rows={5} />
          <label className="pitch-label" htmlFor="pitch-bio"><span className="pitch-label-content" data-content="Notes">Notes</span></label>
        </div>
        <div className={`submitted-files ${this.state.filenames.length > 0 ? 'contains-files': ''}`}>
          {this.state.filenames.map((file, i) => {
            return this.fetchSubmittedFile(file, i);
          })}
        </div>
        <div className="pitch-input-wrap pitch-button-wrap">
          <label className="button upload-button">
            <div className="icon">
              <FontAwesomeIcon icon={faCloudUpload} />
            </div>
            Attach files...
            <FileUploader
              accept="image/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              name="image-uploader-multiple"
              ref={instance => { this.fileUploader = instance; } }
              storageRef={firebase.storage().ref("pitches")}
              onUploadSuccess={this.handleUploadSuccess}
              onUploadError={this.handleError}
              onChange={this.handleFileOnChange}
              hidden
              randomizeFilename
              multiple
            />
          </label>
          <div className="upload-notes">Files accepted: .pdf, .doc, .docx, and any image type.<br />
          Multiple files are fine, but if you have a lot, we recommend combining them into a single or multiple PDFs.</div>
        </div>
        <div className="pitch-input-wrap pitch-button-wrap">
          <button
            className={`button submit-button ${!this.state.formValid ? 'disabled' : ''}`}
            type="submit"
            disabled={!this.state.formValid}
          >
            Submit Pitch
          </button>
        </div>
      </form>
    );
  }
}

export default PitchSubmission;
