import React, { Component } from 'react';
import { Link } from  'react-router-dom';
import BodyClassName from 'react-body-classname';
import LinkItem from './link-item/LinkItem';

class Landing extends Component {
  render() {
    return (
      <BodyClassName className="home-page">
        <div className="content-wrapper">
          <div className="intro">
            <p><strong>UPDATE:</strong> Due to extenuating circumstances Gothology has been indefinitely delayed. We hope to have word soon on when things will be back on track. For now, we will leave pitches open.</p>
            <hr />
            <p>It will have been 10 long years since we gained eternal sadness. Our misery continues to seek company, and we ask you to once again join us in the darkness.</p>
            <p>Gothology Comics is seeking pitches for its 10 year anniversary collection. Pitches should include name, email, link to artwork, story details including outline and page count.</p>
            <LinkItem link={`/pitches`}>Send Us Your Pitches</LinkItem>
            <p>
              <strong>No current due date. Send pitches as your time permits.</strong><br/>
              Questions? Please read our <Link to={`/questions`}>FAQ</Link>.
            </p>
          </div>
        </div>
      </BodyClassName>
    )
  }
}

export default Landing;
